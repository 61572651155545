import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "react-bootstrap"
import alumban from "../images/alumnusbanner.png"
import laap1 from "../images/lifeAsAlumni/soc1.png"
import laap2 from "../images/lifeAsAlumni/soc2.png"
import laap3 from "../images/lifeAsAlumni/soc3.png"
import laap4 from "../images/lifeAsAlumni/soc4.png"
import laaplogo1 from "../images/laaplogo1.png"
import laaplogo2 from "../images/laaplogo2.png"
import laaplogo3 from "../images/laaplogo3.png"
import laaplogo4 from "../images/laaplogo4.png"
import AOS from "aos"
import "aos/dist/aos.css"

import alumPdf from "../../static/assets/alumni-impact-report_2009-2021.pdf"

import ofa1 from "../images/client_logos/ofa1.png"
import ofa2 from "../images/client_logos/ofa2.png"
import ofa3 from "../images/client_logos/ofa3.png"
import ofa4 from "../images/client_logos/ofa4.png"
import ofa5 from "../images/client_logos/ofa5.png"
import ofa6 from "../images/client_logos/ofa6.png"
import ofa7 from "../images/client_logos/ofa7.png"
import ofa8 from "../images/client_logos/ofa8.png"
import ofa9 from "../images/client_logos/ofa9.png"
import ofa10 from "../images/client_logos/ofa10.png"

import oaw1 from "../images/client_logos/oaw1.png"
import oaw2 from "../images/client_logos/oaw2.png"
import oaw3 from "../images/client_logos/oaw3.png"
import oaw4 from "../images/client_logos/oaw4.png"
import oaw5 from "../images/client_logos/oaw5.png"
import oaw6 from "../images/client_logos/oaw6.png"
import oaw7 from "../images/client_logos/oaw7.png"
import oaw8 from "../images/client_logos/oaw8.png"
import oaw9 from "../images/client_logos/oaw9.png"
import oaw10 from "../images/client_logos/oaw10.png"

import psa1 from "../images/client_logos/psa1.png"
import psa2 from "../images/client_logos/psa2.png"
import psa3 from "../images/client_logos/psa3.png"
import psa4 from "../images/client_logos/psa4.png"
import psa5 from "../images/client_logos/psa5.png"
import psa6 from "../images/client_logos/psa6.png"
import psa7 from "../images/client_logos/psa7.png"
import psa8 from "../images/client_logos/psa8.png"
import psa9 from "../images/client_logos/psa9.png"
import psa10 from "../images/client_logos/psa10.png"

const LifeAsAnAlumnus = () => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])
  return (
    <Layout pageName="Life As Alumni">
      <SEO title="Life As Alumni" />
      <div className="ban-bg-black">
        <Container className="py-lg-5">
          <Row className="py-5">
            <div
              className="col-lg-7 pb-5 pb-lg-0 px-5 px-lg-0 order-lg-last"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <img className="w-100" src={alumban} />
            </div>
            <div className="col-lg-5 pr-lg-5 order-lg-first align-center text-center text-lg-left">
              <div>
                <p
                  className="ed-title white mb-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  The Puzzle of Ed-Inequity
                </p>
                <p
                  className="jtf-head white mb-3 my-lg-3"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                >
                  Bringing Systemic Change
                </p>
                <p
                  className="gentxt white mb-3 pb-lg-5"
                  data-aos="fade-up"
                  data-aos-delay="700"
                  data-aos-duration="1000"
                >
                  Addressing the complex challenge of educational inequity is
                  like solving a seemingly impossible jigsaw puzzle;
                  successfully putting the pieces together demands leadership
                  and a collective commitment. Keeping an equitable, excellent
                  education as our focus, in the coming years, we hope to
                  recruit, develop and match more diverse leaders to our three
                  big puzzle pieces. We also intend to advocate for educational
                  equity and get leaders in our regions to work collectively
                  towards our cause.
                </p>
                <a
                  href={alumPdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="butn butn-main"
                  data-aos="fade-up"
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                >
                  View Report
                </a>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Container className="py-5 text-center px-0">
        <div className="col-lg-10 offset-lg-1">
          <p
            className="ed-title mb-lg-3"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Collective Leadership
          </p>
          <p
            className="fi-head mb-lg-5 max-70 max-sm-100 mx-auto"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Committed to Equity <br />
            Fuelled by Belief
          </p>
          <p
            className="fi-txt text-left text-lg-center mb-lg-5 px-lg-5 pb-lg-5"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Teach For India Alumni are setting up and leading non-profit
            organisations and social enterprises that fuel change for India’s
            children in classrooms, schools, and beyond. Through their work and
            networks, they are indirectly reaching more than 33 million children
            - that&apos;s 1 in 10 children in India - at various levels of the
            system from schools, communities to governance and policy - level
            initiatives, and collectively bringing about long lasting change.
          </p>
          <p
            className="fi-head max-70 max-sm-100 mx-auto mt-5"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Organisations founded by our Alumni
          </p>
          <div
            className="col-lg-10 offset-lg-1 my-3 my-lg-5 max-sm-60 mx-auto pb-lg-5"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="client-logo-grid">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://mantra4change.com/"
                className="client-logo-cont"
              >
                <img src={ofa1} className="client-logo w-100" />
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://jungroo.com/"
                className="client-logo-cont"
              >
                <img src={ofa2} className="client-logo w-100" />
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.saturdayartclass.com/"
                className="client-logo-cont"
              >
                <img src={ofa3} className="client-logo w-100" />
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://life-lab.org/home"
                className="client-logo-cont"
              >
                <img src={ofa4} className="client-logo w-100" />
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.facebook.com/shiksharthfoundation/"
                className="client-logo-cont"
              >
                <img src={ofa5} className="client-logo w-100" />
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.samaitshala.org/"
                className="client-logo-cont"
              >
                <img src={ofa6} className="client-logo w-100" />
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://haqdarshak.com/"
                className="client-logo-cont"
              >
                <img src={ofa7} className="client-logo w-100" />
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://reapbenefit.org/"
                className="client-logo-cont"
              >
                <img src={ofa8} className="client-logo w-100" />
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://headsupfortails.com/"
                className="client-logo-cont"
              >
                <img src={ofa9} className="client-logo w-100" />
              </a>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://kanavu.in/"
                className="client-logo-cont"
              >
                <img src={ofa10} className="client-logo w-100" />
              </a>
            </div>
          </div>
          <p
            className="fi-head max-70 mx-auto max-sm-100 pt-lg-5 mt-5"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Organisations our <br />
            Alumni work with
          </p>
          <div
            className="col-lg-10 offset-lg-1 my-3 my-lg-5 max-sm-60 mx-auto pb-lg-5"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="client-logo-grid pb-lg-5">
              <Link to="/" className="client-logo-cont">
                <img src={oaw1} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={oaw2} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={oaw3} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={oaw4} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={oaw5} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={oaw6} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={oaw7} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={oaw8} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={oaw9} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={oaw10} className="client-logo w-100" />
              </Link>
            </div>
            <p className="ed-txt d-none d-sm-block">And many more.</p>
          </div>

          <p
            className="fi-head"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Private sector organisations <br />
            our Alumni work with
          </p>
          <p
            className="fi-txt max-75 max-sm-100 mx-auto"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            Private sector organisations play a critical role in mobilising the
            resources and talent needed for education systems to thrive. After
            completing the Fellowship, several Teach For India Alumni join
            private sector organisations to continue to affect change in
            education.
          </p>
          <div
            className="col-lg-10 offset-lg-1 my-3 my-lg-5 max-sm-60 mx-auto pb-5"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="client-logo-grid pb-5">
              <Link to="/" className="client-logo-cont">
                <img src={psa1} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={psa2} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={psa3} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={psa4} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={psa5} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={psa6} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={psa7} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={psa8} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={psa9} className="client-logo w-100" />
              </Link>
              <Link to="/" className="client-logo-cont">
                <img src={psa10} className="client-logo w-100" />
              </Link>
            </div>
            <p className="ed-txt d-none d-sm-block">And many more.</p>
          </div>
        </div>
      </Container>
      <Container className="container-md-100">
        <p
          className="ed-title text-center text-lg-left"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          The Power of the Movement
        </p>
        <p
          className="fi-head text-center text-lg-left"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          Stories of Change
        </p>
        <div
          className="profile"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <img src={laap1} className="profile-img" />
          <div className="profile-body">
            <p className="profile-title">
              Sethuraman changes <br />
              grim realities with AI
            </p>
            <p className="profile-txt">
              In 2017, Sethuraman founded Jungroo Learning with a vision to
              leverage technology to cater to the unique learning needs of each
              and every child. Getting immersed in the school system gave him
              micro-level insights into the problems faced not only by the
              community but also the education system at large. Armed with AI,
              Jungroo finds the shortest path to mastery for students while
              creating a personalised learning path for them.
            </p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.cnbctv18.com/videos/young-turks/young-turks-heres-how-jungroo-learning-is-customising-learning-for-every-student-4266791.htm"
              className="profile-link"
            >
              Read the entire story
            </a>
          </div>
          <div className="plogo-cont">
            <img src={laaplogo1} className="profile-logo" />
          </div>
        </div>
        <div
          className="profile"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <img src={laap2} className="profile-img" />
          <div className="profile-body">
            <p className="profile-title">Shalini’s transformative journey</p>
            <p className="profile-txt">
              Shalini’s experience as a teacher made her realise that since
              mothers are often the primary caregiver, a child’s well-being,
              therefore, is directly related to their well-being. Through
              conversations with her Students’ mothers, she learnt that while
              they were brave and hopeful, they felt unequipped to bring about
              any change. She began her organisation- ‘Aftertaste’ with two such
              women. Through her love for the arts and creativity- she empowers
              these women to create niche paper products, adorned with
              hand-drawn art.
            </p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.thehindu.com/news/cities/mumbai/a-wider-canvas/article19556200.ece"
              className="profile-link"
            >
              Read the entire story
            </a>
          </div>
          <div className="plogo-cont">
            <img src={laaplogo2} className="profile-logo" />
          </div>
        </div>

        <div
          className="profile"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <img src={laap3} className="profile-img" />
          <div className="profile-body">
            <p className="profile-title">Srini, one of our #BharatKeMahaveer</p>
            <p className="profile-txt">
              Srini Swaminathan, a 2010 Alumnus realized the power of education
              quite early on in his life. He came from humble beginnings,
              followed the traditional path to the Indian dream, and managed to
              get into BITS Pilani. He worked across the globe with an
              international brand for over 8 years before he returned to India
              and joined the second cohort of Teach For India. Our former
              Chennai City Director couldn’t stay idle when migrant workers
              started going back to their hometowns on foot. He started
              distributing food packets and was able to serve 135,000 labourers.
              His efforts were recently recognized globally when the United
              Nations in India and NITI Aayog launched #BharatKeMahaveer, which
              celebrated Indians who have shown extraordinary kindness in the
              wake of COVID-19.
            </p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://expressnews.asia/chennai-covid-warriors-receive-recognition-for-their-noble-efforts-during-the-pandemic-get-featured-in-bharat-ke-mahaveer/"
              className="profile-link"
            >
              Read the entire story
            </a>
          </div>
          <div className="plogo-cont">
            <img src={laaplogo3} className="profile-logo" />
          </div>
        </div>

        <div
          className="profile"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <img src={laap4} className="profile-img" />
          <div className="profile-body">
            <p className="profile-title">Anurag and the Child Rights Charter</p>
            <p className="profile-txt">
              Years ago, a group of 22 class 5 Students at the Bab Ul-Uloom
              School in Seelampur – one of Delhi’s lowest-income localities –
              were assigned a unique project. To devise their own child rights
              charter. “Children should be taught to stand up for what they
              believe in,” says Anurag Kundu, the 2013-2015 Teach For India
              Fellow who was responsible for the class. He had named them,
              aspirationally, the ‘college scholars’. He noticed one thing
              featured prominently in every Student’s charter – that every child
              should be entitled to a good education. Today, he leads the Delhi
              Commission For Protection of Child Rights.
            </p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youthkiawaaz.com/2017/05/fighting-for-the-right-to-learn-one-mans-fight-for-educational-justice/"
              className="profile-link"
            >
              Read the entire story
            </a>
          </div>
          <div className="plogo-cont">
            <img src={laaplogo4} className="profile-logo" />
          </div>
        </div>
      </Container>
      <Container>
        <p
          className="fi-head text-center mt-lg-5 pt-5  "
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          InnovatED
        </p>
        <p className="fi-txt pb-lg-4 pb-5 max-70 max-sm-100 mx-auto">
          Teach For India’s initiative to support early-stage education
          entrepreneurs from the Teach For India network who are looking to take
          their innovations from the project or prototype stage to a sustainable
          organisation.
        </p>
        <div
          className="col-12 text-center my-lg-5 pb-lg-5"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <Link to="/innovation" className="butn butn-main butn-spl">
            Know More
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export default LifeAsAnAlumnus
